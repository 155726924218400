import { BranchInfoType } from "../types";
export const convertToAdvisorTreeSelectOptions = (
  advisorData: BranchInfoType[],
  isAddEvent = false
) => {
  const treeSelectOptions = advisorData?.map((v: BranchInfoType) => {
    return {
      value: v.branchCode,
      title: `${v.branchName}(${v.branchCode})`,
      disabled: !isAddEvent,
      children: v.advisors.map((v) => {
        return {
          value: v.companyNumber,
          title: `${v.name}(${v.companyNumber})`,
        };
      }),
    };
  });

  return [
    {
      value: isAddEvent ? "all" : "",
      title: isAddEvent ? "전체" : "전체 지사",
      children: treeSelectOptions,
    },
  ];
};

export const convertToBranchOptions = (advisorData: BranchInfoType[]) => {
  return advisorData?.map((v) => {
    return {
      label: `${v.branchName}(${v.branchCode})`,
      value: v.branchCode,
    };
  });
};

export const convertAssigneeOptions = (branchData: BranchInfoType) => {
  return branchData?.advisors.map((v) => {
    return {
      label: `${v.name}(${v.companyNumber})`,
      value: v.companyNumber,
    };
  });
};
