import ContentTitle from "../../../components/ui/ContentTitle";
import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { apiCall } from "../../../utils/apiCall";
import {
  Calendar,
  Select,
  Space,
  TreeSelect,
  Tooltip,
  message,
  Drawer,
  Button,
  Form,
  Input,
  DatePicker,
  TimePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import requiredRule from "../../../utils/formRules/requiredRule";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import dayjs, { Dayjs } from "dayjs";
import {
  monthMapping,
  CalendarDataType,
  EventDataType,
  eventTypeList,
  addableEventTypeList,
} from "./utils/data";
import { DeleteOutlined } from "@ant-design/icons";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { ServiceTypes } from "../../../types/types";
import { BranchInfoType } from "../leadsManagement/types";
import fetchAdvisorsData from "../leadsManagement/utils/fetchAdvisorsData";
import { convertToAdvisorTreeSelectOptions } from "../leadsManagement/utils/convertToAdvisorTreeSelectOptions";
import "./Calendar.scss";

interface HeaderRenderProps {
  value: Dayjs; // 當前日曆的日期
  onChange: (date: Dayjs) => void; // 更新日曆日期的方法
}

interface SalesCalendarListProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const SalesCalendar = ({
  setSelectedService,
  setSelectSubItem,
}: SalesCalendarListProps) => {
  const [dataDisplay, setDataDisplay] = useState<EventDataType[]>([]);
  const [eventTypeCount, setEventTypeCount] = useState([0, 0, 0, 0, 0]);
  const [advisorsTreeSelectOptions, setAdvisorsTreeSelectOptions] =
    useState<any>([]);
  const [advisors, setAdvisors] = useState<BranchInfoType[]>([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventDataType | null>(
    null
  );
  const [isAddMode, setIsAddMode] = useState(false);
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { SHOW_PARENT } = TreeSelect;

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const currentDate = dayjs(); // 獲取當前時間
  const startDate = currentDate.subtract(1, "year"); // 計算一年前的日期
  const endDate = currentDate; // 當前時間即為結束日期

  const headerRender = ({ value, onChange }: HeaderRenderProps) => {
    const currentYear = value.year();
    const currentMonth = value.month();
    setSelectedMonth(currentMonth + 1 + "");
    setSelectedYear(currentYear + "");

    // 動態計算年份範圍
    const yearOptions = [];
    for (let year = startDate.year(); year <= endDate.year(); year++) {
      yearOptions.push(
        <Select.Option key={year} value={year}>
          {year}
        </Select.Option>
      );
    }

    // 動態計算月份範圍
    const monthOptions = [];
    const isStartYear = currentYear === startDate.year();
    const isEndYear = currentYear === endDate.year();
    const startMonth = isStartYear ? startDate.month() : 0;
    const endMonth = isEndYear ? endDate.month() : 11;

    for (let i = startMonth; i <= endMonth; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i}>
          {monthMapping[i]}
        </Select.Option>
      );
    }

    // 切換年份
    const handleYearChange = (year: number) => {
      let newValue = value.clone().year(year);
      if (year === startDate.year()) {
        newValue = newValue.month(startDate.month()); // 鎖定到範圍的起始月份
      }
      if (year === endDate.year() && newValue.month() > endDate.month()) {
        newValue = newValue.month(endDate.month()); // 鎖定到範圍的結束月份
      }
      onChange(newValue);
    };

    // 切換月份
    const handleMonthChange = (month: number) => {
      const newValue = value.clone().month(month);
      onChange(newValue);
    };

    return (
      <div style={{ padding: 8 }} className="flex justify-between">
        <Space>
          {/* 年份選單 */}
          <Select
            value={currentYear}
            onChange={handleYearChange}
            className="short-select"
          >
            {yearOptions}
          </Select>

          {/* 月份選單 */}
          <Select
            value={currentMonth}
            onChange={handleMonthChange}
            className="short-select"
          >
            {monthOptions}
          </Select>
          {eventTypeList.map((type, i) => {
            return (
              <div className="flex items-center ms-3">
                <div
                  style={{
                    borderRadius: "50%",
                    height: "6px",
                    width: "6px",
                    backgroundColor: type.color,
                    marginRight: "6px",
                  }}
                />
                <p>{`${type.text} ${eventTypeCount[i]}`}</p>
              </div>
            );
          })}
        </Space>
        <TreeSelect
          placeholder="지사&담당자 선택"
          treeData={advisorsTreeSelectOptions}
          className="long-select"
          onChange={(e) => setSelectedAdvisor(e)}
        />
      </div>
    );
  };

  const formatTime = (time: string): string => {
    const [hour, minute] = time.split(":").map(Number); // 拆分小時和分鐘
    const period = hour >= 12 ? "오후" : "오전"; // 判斷上午或下午
    const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // 轉換為 12 小時制
    return `${period} ${formattedHour}:${minute < 10 ? `0${minute}` : minute}`;
  };

  const dateCellRender = (value: Dayjs) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const events = selectedAdvisor
      ? dataDisplay.filter(
          (item) =>
            item.date === formattedDate &&
            item.data.companyNumber === selectedAdvisor
        )
      : dataDisplay.filter((item) => item.date === formattedDate);
    return (
      <ul
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {events.map((unit, index) => (
          <Tooltip
            color={
              eventTypeList.find((item) => item.value === unit.data.category)
                ?.color
            }
            title={
              <div className="p-2">
                <p>
                  {
                    eventTypeList.find(
                      (item) => item.value === unit.data.category
                    )?.text
                  }
                </p>
                <p className="mt-2">{formatTime(unit.data.startTime)}</p>
                <p className="mt-2">{unit.data.name}</p>
              </div>
            }
          >
            <li
              key={index}
              className="flex items-center"
              onClick={(e) => {
                setDrawerOpen(true);
                setSelectedEvent(unit);
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                  backgroundColor: eventTypeList.find(
                    (item) => item.value === unit.data.category
                  )?.color,
                  marginRight: "8px",
                }}
              />
              {formatTime(unit.data.startTime) + " " + unit.data.name}
            </li>
          </Tooltip>
        ))}
      </ul>
    );
  };

  const handleDateSelect = (value: Dayjs) => {
    setSelectedDate(value);
    setDrawerOpen(true);
    setIsAddMode(true);
  };

  const fetchData = async (year: string, month: string) => {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint:
        month.length > 1
          ? process.env.REACT_APP_SALES_ADMIN +
            "/events/admin/" +
            year +
            "/" +
            month
          : process.env.REACT_APP_SALES_ADMIN +
            "/events/admin/" +
            year +
            "/0" +
            month,
    });
    if (data) {
      const transformedData = data.flatMap((item: CalendarDataType) =>
        item.data.map((event) => ({
          date: item.date,
          data: event,
        }))
      );
      setDataDisplay(transformedData);
    }
  };

  const handleDrawerSubmit = async () => {
    const { title, date, startTime, endTime, shareTo, type } =
      form.getFieldsValue();
    const datePart = dayjs(date).format("YYYY-MM-DD");
    const timePart = dayjs(startTime).format("HH:mm:ss");
    const start = datePart + "T" + timePart + ".000Z";
    let allAdvisors: string[] = [];
    advisors.forEach((branch) => {
      allAdvisors = allAdvisors.concat(
        branch.advisors.map((advisor) => advisor.companyNumber)
      );
    });
    const payload = {
      title: title,
      category: type,
      startDate: start,
      companyNumbersToShare:
        shareTo.length === 1 && shareTo[0] === "all"
          ? allAdvisors
          : shareTo.flatMap((item: string) =>
              item.startsWith("HQ")
                ? advisors
                    .find((branch) => branch.branchCode === item)
                    ?.advisors.map((advisor) => advisor.companyNumber)
                : [item]
            ),
      ...(endTime
        ? {
            endDate:
              datePart + "T" + dayjs(endTime).format("HH:mm:ss") + ".000Z",
          }
        : {}),
    };
    await apiCall({
      method: "POST",
      header: header,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/events/admin",
      payload: payload,
    });
    setDrawerOpen(false);
    message.success(renderAntDMessageConfig("일정 등록을 완료하였습니다."));
    setIsAddMode(false);
    fetchData(selectedYear, selectedMonth);
  };

  useEffect(() => {
    fetchData(selectedYear, selectedMonth);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("업무 관리");
  }, []);

  useEffect(() => {
    fetchAdvisorsData(header, setAdvisors);
  }, []);

  useEffect(() => {
    if (advisors.length > 0) {
      setAdvisorsTreeSelectOptions(
        convertToAdvisorTreeSelectOptions(advisors, true)
      );
    }
  }, [advisors]);

  useEffect(() => {
    if (dataDisplay) {
      setEventTypeCount([
        dataDisplay.filter((event) => event.data.category === "appo").length,
        dataDisplay.filter((event) => event.data.category === "demo").length,
        dataDisplay.filter((event) => event.data.category === "meeting").length,
        dataDisplay.filter((event) => event.data.category === "education")
          .length,
        dataDisplay.filter((event) => event.data.category === "other").length,
      ]);
    }
  }, [dataDisplay]);

  useEffect(() => {
    if (selectedDate) {
      form.setFieldValue("date", selectedDate);
    }
  }, [selectedDate]);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Business Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="calendarWrapper">
      <ContentTitle title="월간 일정" />
      <div className="content-container">
        <Calendar
          mode="month"
          headerRender={headerRender}
          cellRender={dateCellRender}
          onSelect={handleDateSelect}
        />
      </div>
      <Drawer
        title={!isAddMode ? "일정 상세" : "일정 공지"}
        open={drawerOpen}
        footer={
          isAddMode ? (
            <div className="flex justify-end p-3">
              <Button
                onClick={() => {
                  setDrawerOpen(false);
                  form.resetFields();
                }}
                className="me-3"
              >
                취소
              </Button>
              <Button
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      handleDrawerSubmit();
                    })
                    .catch((e) => console.log(e));
                }}
                type="primary"
              >
                확인
              </Button>
            </div>
          ) : (
            <div className="flex justify-between p-3">
              <Button danger icon={<DeleteOutlined />}>
                삭제
              </Button>
              <Button
                onClick={() => {
                  setDrawerOpen(false);
                  setSelectedEvent(null);
                }}
              >
                닫기
              </Button>
            </div>
          )
        }
        onClose={() => {
          setDrawerOpen(false);
          setSelectedEvent(null);
          setIsAddMode(false);
          form.resetFields();
        }}
      >
        {isAddMode ? (
          <div>
            <Form form={form} name="event-form" layout="vertical">
              <Form.Item label="일정 유형" name="type" rules={[requiredRule]}>
                <Select
                  placeholder="일정 유형을 선택해주세요"
                  options={addableEventTypeList.map((option) => ({
                    value: option.value,
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            borderRadius: "50%",
                            height: "6px",
                            width: "6px",
                            backgroundColor: option.color,
                            marginRight: "8px",
                          }}
                        />
                        {option.text}
                      </div>
                    ),
                  }))}
                />
              </Form.Item>
              <Form.Item label="제목" name="title" rules={[requiredRule]}>
                <Input placeholder="제목을 입력해주세요" />
              </Form.Item>
              <Form.Item label="날짜" name="date" rules={[requiredRule]}>
                <DatePicker className="w-full" />
              </Form.Item>
              <Form.Item
                label="시작 시간"
                name="startTime"
                rules={[requiredRule]}
              >
                <TimePicker
                  className="w-full"
                  placeholder="시작 시간을 선택해주세요"
                />
              </Form.Item>
              <Form.Item label="종료 시간" name="endTime">
                <TimePicker
                  className="w-full"
                  placeholder="종료 시간을 선택해주세요"
                />
              </Form.Item>
              <Form.Item
                label="공유 대상"
                rules={[requiredRule]}
                name="shareTo"
              >
                <TreeSelect
                  placeholder="공유 대상을 선택해주세요"
                  multiple
                  treeData={advisorsTreeSelectOptions}
                  treeCheckable
                  showCheckedStrategy={SHOW_PARENT}
                />
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div id="eventDrawerWrapper">
            <p>지사</p>
            <p className="mt-1 content">{`${selectedEvent?.data.branchName}(${selectedEvent?.data.branchCode})`}</p>
            <p className="mt-5">담당자</p>
            <p className="mt-1 content">{`${selectedEvent?.data.name}(${selectedEvent?.data.companyNumber})`}</p>
            <p className="mt-5">일정 유형</p>
            <div className="flex mt-1 items-center">
              <div
                style={{
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                  backgroundColor: eventTypeList.find(
                    (item) => item.value === selectedEvent?.data.category
                  )?.color,
                  marginRight: "8px",
                  marginLeft: "2px",
                }}
              />
              <p className="content">
                {
                  eventTypeList.find(
                    (item) => item.value === selectedEvent?.data.category
                  )?.text
                }
              </p>
            </div>
            <p className="mt-5">
              {selectedEvent?.data.category === "appo" ||
              selectedEvent?.data.category === "demo"
                ? "리드명"
                : "일정"}
            </p>

            {selectedEvent?.data.category === "appo" ||
            selectedEvent?.data.category === "demo" ? (
              <Button
                type="link"
                className="p-0"
                onClick={() => {
                  navigate(
                    `/sales188/lead-detail/${selectedEvent.data.leadNo}`
                  );
                }}
              >
                {`${selectedEvent?.data.customerName}(${selectedEvent.data.leadNo})`}
              </Button>
            ) : (
              <p className="mt-1 content">{selectedEvent?.data.title}</p>
            )}

            <p className="mt-5">날짜</p>
            <p className="mt-1 content">{`${selectedEvent?.date}`}</p>
            <p className="mt-5">시작 시간</p>
            <p className="mt-1 content">{`${
              selectedEvent?.data.startTime
                ? formatTime(selectedEvent?.data.startTime)
                : ""
            }`}</p>
            <p className="mt-5">종료 시간</p>
            <p className="mt-1 content">{`${
              selectedEvent?.data.endTime
                ? formatTime(selectedEvent?.data.endTime)
                : ""
            }`}</p>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default SalesCalendar;
